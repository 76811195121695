import React from 'react';

import PropTypes from 'prop-types';
import KMBChat from '@components/layout/KMBChat';
import KMBLoader from '@components/layout/KMBLoader';
import Thumbnail from '../Thumbnail';
import Mail from '@components/layout/Mail';
import NavigateModal from '@components/layout/NavigateModal';
import CallToAction from '../CallToAction';
import SponsorImage from '../SponsorImage';
import Iframe from '../Iframe';
import Player from '../Player';

const sortByIndex = (a, b) => {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
};
export default class SingleVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      videoUrl: null,
      otherVideos: [],
      sessionId: null,
      speechId: null,
      ready: true,
      isAutoPlay: false,
      errorUrl: '',
      mailto: '',
      sessionName: '',
      videoTitle: <span />,
      videoName: '',
      completed: false,
      srcUrl: '',
      question: '',
      name: '',
      session: null,
      width: window.innerWidth,
      selectedModal: 'player',
      modalIsOpen: false,
      linkToNavigateTo: '',
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.navigateOutOfSiteHandler = this.navigateOutOfSiteHandler.bind(this);
    this.setSiteToNavigateTo = this.setSiteToNavigateTo.bind(this);

    this.sponsorSchema = {};
    this.playerRef = null;
    this.updating = false;
    this.position = 0;
    this.status = '';

    this.statsInterval = parseInt(Math.random() * 120000 + 4 * 60000); //aproximatly 5 minutes
    // this.statsInterval = 2000; //send stats every 2 secs, while playing
    this.timeoutStats = setInterval(() => {}, this.statsInterval);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadSessions();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const checkProps = JSON.stringify(nextProps) !== JSON.stringify(this.props);
    const checkState =
      nextProps.sessionId != this.state.sessionId ||
      nextProps.speechId != this.state.speechId;
    if ((!this.updating && checkProps) || checkState) {
      this.position = 0;
      this.setSponsorSchema();

      this.setComponent(nextProps);
    }
  }
  setSponsorSchema() {
    this.sponsorSchema =
      this.props.event.clientPanelSettings.videos.sponsorsSchema.sort(
        sortByIndex
      );
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  selectedModal(renderPlayer, renderChat, renderIframe) {
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    if (renderPlayer && this.state.selectedModal === 'player') {
      return <Player {...this.state} {...this.props} />;
    }
    if (renderChat && this.state.selectedModal === 'chat') {
      return (
        <KMBChat
          sessionId={this.state.sessionId}
          chatCoordinator={
            this.state.session.chatCoordinator === this.props.userId
          }
          color={primaryColor}
        />
      );
    }
    if (renderIframe && this.state.selectedModal === 'player') {
      <Iframe srcUrl={this.state.srcUrl} videoTitle={this.state.videoTitle} />;
    }
  }
  setComponent(props) {
    let videoUrl,
      srcUrl,
      videoTitle,
      videoImage,
      isAutoPlay,
      mailto,
      videoName,
      sessionName,
      session,
      errorUrl;
    let theSession = null;
    theSession = props.sessions.filter(
      (_session) => _session.id == props.sessionId
    )[0];

    if (props.sessions && theSession) {
      session = theSession;
      videoUrl = theSession.videoUrl;
      srcUrl = theSession.srcUrl;
      videoImage =
        theSession?.videoSessionThumbnail?.length > 0
          ? theSession.videoSessionThumbnail[0].url
          : theSession?.videoThumbnail;
      isAutoPlay = theSession.liveStream == 1;
      mailto = theSession.questionsEmail || '';
      errorUrl = theSession.urlStream || '';
      sessionName = theSession.name || '';

      videoTitle = (
        <div>
          <div className="video-title">{theSession.name}</div>
        </div>
      );
      videoName = theSession.name;
      if (Number(props.speechId)) {
        const theSpeech = theSession.speeches.filter(
          (speech) => speech.id == props.speechId
        )[0];
        videoUrl = theSpeech.videoUrl;
        srcUrl = theSpeech.iframeUrl;
        videoImage =
          theSpeech?.videoSpeechThumbnail?.length > 0
            ? theSpeech.videoSpeechThumbnail[0].url
            : theSpeech?.videoThumbnail;
        isAutoPlay = theSpeech.liveStream == 1;
        videoTitle = (
          <div>
            <div className="video-title">{theSpeech.name}</div>
          </div>
        );
        videoName = theSpeech.name;
      }

      const videos = [];
      for (const i in props.sessions) {
        if (
          props.sessions[i].selfHostingVideo ||
          props.sessions[i].thirdPartyHosting
        ) {
          if (props.speechId || props.sessionId != props.sessions[i].id) {
            videos.push(
              <Thumbnail
                i={i}
                sessions={props.sessions}
                showVideo={props.showVideo}
                language={props.language}
              />
            );
          }
        }
        for (const j in props.sessions[i].speeches) {
          if ((props.sessions[i].speeches[j].videoUrl || '').length > 0) {
            if (props.speechId != props.sessions[i].speeches[j].id) {
              videos.push(
                <Thumbnail
                  j={j}
                  i={i}
                  sessions={props.sessions}
                  showVideo={props.showVideo}
                  language={props.language}
                />
              );
            }
          }
        }
      }
      this.updating = true;

      this.setState(
        {
          srcUrl,
          videoUrl,
          videoTitle,
          videoName,
          errorUrl,
          videoImage,
          isAutoPlay,
          mailto,
          sessionName,
          otherVideos: videos,
          sessionId: props.sessionId,
          speechId: props.speechId,
          session: session,
          errorStream: false,
          ready: true,
        },
        () => {
          this.updating = false;
          const hasSubscription =
            this.props.user.subscription &&
            Object.keys(this.props.user.subscription).length > 0;
          const registrationCompleted = this.props.mandatoryPayment
            ? this.props.user &&
              (this.props.user.subscriptionDueAmount <= 0 ||
                this.props.user.info.sponsored == 1)
            : true;

          // when iframe store the user event in site time
          if (
            (this.state.srcUrl || '').length > 0 &&
            this.state.session.thirdPartyHosting &&
            !this.state.speechId &&
            this.state.session.enableVideoMinutes &&
            ((hasSubscription && registrationCompleted) || !hasSubscription)
          ) {
            this.position = Date.now() / 1000;
            this.setIframeStatistics();
          }
        }
      );
    }
  }
  componentWillUnmount() {
    clearInterval(this.timeoutStats);
  }

  // this is for iframe statistics only
  // this statistics are not accurate but
  // can give the administrator a hint about how
  // many participants joined the actual page
  handleEvents() {
    const now = Date.now() / 1000;
    this.props
      .watchtimeStatistics(this.props.event.id, {
        eventUserId: this.props.userId,
        eventSessionId: this.props.sessionId,
        eventSpeechId: null,
        time: parseInt(now - this.position),
        type: 'end',
        uid: this.state.uid || undefined,
      })
      .then((response) => {
        if (!this.state.uid) this.setState({ uid: response.data.uid });
      });
  }

  setIframeStatistics() {
    clearInterval(this.timeoutStats);
    this.timeoutStats = setInterval(() => {
      this.handleEvents();
    }, this.statsInterval);
  }
  setSiteToNavigateTo(link) {
    if (link.includes('bluetree')) {
      window.open(link);
    } else {
      this.setState({ linkToNavigateTo: link }, () =>
        this.navigateOutOfSiteHandler()
      );
    }
  }
  navigateOutOfSiteHandler() {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  }
  render() {
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    if (!this.props.ready || !this.state.ready) {
      return <KMBLoader rows={20} />;
    }

    const registrationCompleted = this.props.mandatoryPayment
      ? this.props.user &&
        (this.props.user.subscriptionDueAmount <= 0 ||
          this.props.user.info.sponsored == 1)
      : true;
    const renderIframe = !this.state.speechId
      ? this.state.srcUrl?.length > 0 &&
        this.state.session.thirdPartyHosting == 1
      : this.state.videoUrl?.length > 0
      ? false
      : this.state.srcUrl?.length > 0
      ? true
      : false;

    const renderPlayer = !renderIframe;

    const renderCallToAction =
      this.state.session !== null && this.state.session.callToAction == 1;

    const renderCreateMail =
      this.state.mailto.length > 0 &&
      this.state.session.questions == 1 &&
      this.props.speechId == 0;

    const renderChat =
      this.state.session !== null && this.state.session.chat == 1;

    const hasSubscription =
      this.props.user.subscription &&
      Object.keys(this.props.user.subscription).length > 0;
    return (
      <div className={`container `}>
        {hasSubscription && !registrationCompleted ? (
          <div>
            <div className="empty-results">
              <span className="icon-warning-2" />
              <div className="speech-bubble">
                <p>
                  {window.lang[this.props.language]['hi']}{' '}
                  {this.props.user.info.firstName},{' '}
                  {window.lang[this.props.language]['registrationNotCompleted']}
                </p>
                <p>{window.lang[this.props.language]['videoDuesMessage']}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.changeSection('account', 'billing')}
                  style={{ marginTop: '20px' }}>
                  {window.lang[this.props.language]['goToPayments']}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`single-video ${
              this.state.modalIsOpen ? 'modal-open' : ''
            }`}>
            {this.state.width <= 991 && (renderChat || renderCreateMail) && (
              <div className="mobile-selector">
                <span
                  className={
                    this.state.selectedModal === 'player' ? 'active' : ''
                  }
                  onClick={() => this.setState({ selectedModal: 'player' })}>
                  Video
                </span>
                <span
                  className={
                    this.state.selectedModal === 'chat' ? 'active' : ''
                  }
                  onClick={() => this.setState({ selectedModal: 'chat' })}>
                  Chat
                </span>
              </div>
            )}
            <div className="video-chat-container">
              <div
                style={{
                  width:
                    this.state.width > 991 && (renderChat || renderCreateMail)
                      ? 'calc(100% - 400px)'
                      : '100%',
                }}>
                {renderIframe && (
                  <Iframe
                    hidden={
                      this.state.selectedModal === 'chat' &&
                      this.state.width <= 991
                    }
                    srcUrl={this.state.srcUrl}
                    videoTitle={this.state.videoTitle}
                    callToAction={
                      renderCallToAction && (
                        <CallToAction
                          session={this.state.session}
                          language={this.props.language}
                          color={primaryColor}
                          setSiteToNavigateTo={this.setSiteToNavigateTo}
                        />
                      )
                    }
                  />
                )}
                {renderPlayer && (
                  <Player
                    hidden={
                      this.state.selectedModal === 'chat' &&
                      this.state.width <= 991
                    }
                    {...this.state}
                    {...this.props}
                    callToAction={
                      renderCallToAction && (
                        <CallToAction
                          session={this.state.session}
                          language={this.props.language}
                          color={primaryColor}
                          setSiteToNavigateTo={this.setSiteToNavigateTo}
                        />
                      )
                    }
                  />
                )}
              </div>
              {(renderChat || renderCreateMail) && (
                <div
                  className="video-communication"
                  style={
                    this.state.width > 991
                      ? { width: 400, paddingLeft: 20 }
                      : {
                          width: '100%',
                        }
                  }>
                  {renderChat && (
                    <KMBChat
                      hidden={
                        this.state.selectedModal === 'player' &&
                        this.state.width <= 991
                      }
                      sessionId={this.state.sessionId}
                      chatCoordinator={
                        this.state.session.chatCoordinator === this.props.userId
                      }
                      color={primaryColor}
                    />
                  )}
                  {renderCreateMail && (
                    <Mail
                      hidden={
                        this.state.selectedModal === 'player' &&
                        this.state.width <= 991
                      }
                      {...this.state}
                      {...this.props}
                    />
                  )}
                </div>
              )}
            </div>
            <div>
              {this.sponsorSchema.length > 0 && (
                <SponsorImage
                  sponsorSchema={this.sponsorSchema}
                  language={this.props.language}
                  setSiteToNavigateTo={this.setSiteToNavigateTo}
                />
              )}
            </div>
          </div>
        )}
        {this.state.modalIsOpen && (
          <NavigateModal
            changeModalStatus={this.navigateOutOfSiteHandler}
            linkToNavigateTo={this.state.linkToNavigateTo}
            language={this.props.language}
            color={primaryColor}
          />
        )}
      </div>
    );
  }
}

SingleVideo.propTypes = {
  loadSessions: PropTypes.func,
  event: PropTypes.object,
  sessionId: PropTypes.number,
  speechId: PropTypes.number,
  watchtimeStatistics: PropTypes.func,
  userId: PropTypes.number,
  mandatoryPayment: PropTypes.number,
  user: PropTypes.object,
  createCustomEmail: PropTypes.func,
  language: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  addNotification: PropTypes.func.isRequired,
  sessions: PropTypes.array,
};
